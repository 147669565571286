import { createRoot } from "react-dom/client";

import "../globals/LegacyModalService";

import "./settings/mutedCompaniesSelector";
import "./settings/SettingsDropdown";

import "./lds/notifications/Toast";
import "./shared/notifications/FlashToast";

import "./lds/modal/GenericLegacyModal";

import "./generic/genericFilterItemRemove";
import "./generic/modals/genericModal";
import "./generic/modals/genericModalActions";
import "./generic/modals/genericModalBody";
import "./generic/modals/genericModalHeader";
import "./generic/modals/GenericErrorModal";

import "./generic/NavBarItems";

import "./generic/autocomplete/genericAutocomplete";
import "./companyDashboard/affiliatedWithCards";

import "./companyDashboard/cumulativeFundingCalendarYears";
import "./companyDashboard/fundingEventsCard";
import "./companyDashboard/activeStudiesCard";
import "./companyDashboard/salesforce/sfPanelWrapper";

import "./companyDashboard/relatedTherapiesContainer";
import "./companyDashboard/salesforce/sfFormModal";

import "./admin/commentaryArticle/articleEventPanel";
import "./admin/administrateEvent/eventListPanel";

import "./facetSearch/facetSections";
import "./facetSearch/facetListContainer";
import "./facetSearch/facetListHeader";
import "./facetSearch/facetListItem";
import "./facetSearch/facetList";

import "./facetSearch/facetSectionShowMore";
import "./facetSearch/locationSelector";

import "./facetSearch/companyRelationshipSelector";
import "./facetSearch/organizationSelector";
import "./facetSearch/conditionSelector";
import "./facetSearch/therapySelector";
import "./facetSearch/trialsSelector";
import "./facetSearch/userListSelector";
import "./facetSearch/clinicalTrialStagesSelector";
import "./facetSearch/clinicalTrialStatusSelector";

import "./facetSearchCriteria/facetSearchCriteriaContainer";
import "./facetSearch/TimeSelectDropdown";
import "./facetSearch/timeSelector/FacetSearchTimeSelector";

import "./search_description/searchDescriptionPanel";
import "./feeds/FeedsIndexTable";

import "./flagged_events/flagEventModal";
import "./flagged_events/flaggingEventPanel";

import "./lists/listModalPanel/CopyToNewListSuccess";

import "./lists/downloadPrimoPlModal/downloadPrimoPlModal";
import "./lists/listDisplay/listDisplay";
import "./lists/listFeedPanel";
import "./lists/deleteListsConfirmationModal";

import "./lists/premiumBaby";
import "./lists/shareListModal";
import "./lists/listModalPanel/listModalPanel";
import "./lists/listModalPanel/existingCopyList";

// Lists V2
import "./lists/v2/ListPage";
import "./lists/v2/ListCreateModal";

import "./segmentContact/chooseSegmentContactReport";

import "./signInLinks";
import "./users/paginatedSelectableListOfUsers";
import "./modals/SearchErrorModal";
import "./modals/SessionTimeoutModal";
import "./territorySelector";

import "./companyDashboard/DashboardNavTabs";
import "./companyDashboard/studiesCard";
import "./companyDashboard/therapeuticAreaCard";

import "./generic/htmlElements/bootstrapFullWidthRow";
import "./generic/htmlElements/bootstrapRow";
import "./generic/htmlElements/bootstrapSpan";
import "./generic/htmlElements/twoColumnList";
import "./generic/htmlElements/unorderedList";

import "./generic/modals/adminModal";
import "./generic/modals/adminModalBody";
import "./generic/modals/adminModalFooter";
import "./generic/modals/adminModalHeader";

import "./generic/pagination/paginationHolder";
import "./generic/pagination/paginationLinks";
import "./generic/autocomplete/autocompleteSuggestions";
import "./generic/searchInput";

import "./contactSearch/contactSearchPanel";
import "./devTools/devTools";

import "./lists/CopyToExistingListDropdown";
import "./feeds/IncludeFeedsOnHomePageCheckbox";

import "./flagged_events/FeedsTabs";
import "./flagged_events/FlagEventsTabs";
import "./flagged_events/FlagEventStatusPanel";
import "./flagged_events/LDSFlagEventModal";
import "./flagged_events/LDSFlagEventModalSuccess";

import "./feeds/ViewFeedTabs";
import "./feeds/LDSFeedSaveModal";

import "./home/HomeFeedTableBody";

// TODO alpha
import "./salesforce/SalesforceModal";

window.createRoot = createRoot;
