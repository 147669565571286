import _ from "underscore";

import Button from "@javascript/reactComponents/lds/Button";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";

import Heading from "@javascript/reactComponents/lds/typography/Heading";
import Icon from "@javascript/reactComponents/lds/Icon";
import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  ModalBody,
  ModalFooter,
} from "@javascript/reactComponents/lds/modal/Modal";

import {
  SalesforceFormErrorMessages,
  SalesforceModalFormIssue,
  SalesforceModalLoading,
} from "@javascript/reactComponents/salesforce/SalesforceModalMessages";

import SfFormFieldsCore from "@javascript/reactComponents/companyDashboard/salesforce/sfFormFieldsCore";
import SfFormFieldsOptional from "@javascript/reactComponents/companyDashboard/salesforce/sfFormFieldsOptional";

const SfAccountForm = createReactClass({
  getInitialState(){
    return {
      coreFields: null,
      created: false,
      errorBuildingForm: false,
      errors: false,
      optionalFields: null,
    };
  },
  retrieveSfAccountFields(){
    let url = "/company/" + this.props.org_id + "/retrieve_salesforce_account_fields";
    let self = this;

    $.ajax({
      url: url,
      type: "GET",
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        const validFields = crmHelpers.pruneInvalidFields(results.fields);
        const [coreFields, optionalFields] = _.partition(validFields, (field) => field.opt_custom !== true);
        self.setState({
          coreFields: coreFields,
          optionalFields: optionalFields,
        });
      },
      error: function(e) {
        console.error(e);
        self.setState({ errorBuildingForm: true });
      },
      statusCode: {
        401: () => { 
          location.reload()
        }
      }
    });
  },
  createAccount(e) {
    e.preventDefault();
    const data = $(".sf-account-form").serialize();
    const url = "/company/" + this.props.org_id + "/create_salesforce_account";
    let self = this;

    $.ajax({
      url: url,
      type: "POST",
      data: data,
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        if (results.response.status === 400) {
          self.setState({ errors: {error_fields: results.error_fields, error_messages: results.error_messages, error_codes: results.error_codes} });
        } else {
          self.setState({ errors: false, created: true });
          SFPanelWrapperActions.updateAccounts(results.accounts);
        }
      }
    });
  },

  // Renders the form entities
  // In the initial state it checks if state.coreFields has value:
  // IF IT DOES => Render the form with the fields
  // IF NOT     => Then call retrieve() fields to get the required fields from the Restforce API
  //               and display a message to the user informing them to wait while the form is
  //               being generated
  render() {
    if (this.state.coreFields) {
      if (this.state.created) {
        return (
          <>
            <ModalBody className="flex-col align-center justify-center">
              <Icon
                name={"special-success"}
              />
              <Heading size="h2">Account added successfully!</Heading>
            </ModalBody>
            <ModalFooter align="flex-end">
              <Button
                onClick={LegacyModalService.closeModal}
                size="medium"
                variant="primary"
              >
                Close
              </Button>
            </ModalFooter>
          </>
        );
      }
      else {
        return (
          <>
            <form className="sf-account-form">
              <ModalBody className="lds-modal-body-height">
                <SfFormFieldsCore
                  errors={this.state.errors}
                  fields={this.state.coreFields}
                />
                <SfFormFieldsOptional
                  errors={this.state.errors}
                  fields={this.state.optionalFields}
                />
              </ModalBody>
              <ModalFooter align="flex-end">
                <SalesforceFormErrorMessages
                  errors={ this.state.errors }
                />
                <ButtonGroup size="medium">
                  <Button
                    onClick={LegacyModalService.closeModal}
                    size="medium"
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={this.createAccount}
                    size="medium"
                    variant="primary"
                  >
                    Add Account
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          </>
        );
      }
    }
    else {
      if (this.state.errorBuildingForm) {
        return <SalesforceModalFormIssue />
      }
      else {
        this.retrieveSfAccountFields();
        return <SalesforceModalLoading />
      }
    }
  }
});

export default SfAccountForm;
