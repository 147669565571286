import AvailableColumns from "../availableColumns/availableColumns";
import CancelColumnsButton from "./cancelColumnsButton";
import ChosenColumns from "../chosenColumns/chosenColumns"
import HeaderRow from "./headerRow";

let hacky_timer;

const ModifyListCheckbox = createReactClass({
  mixins: [Reflux.listenTo(ColumnControlsStore,"toggle_controls")],
  getInitialState: function(){
    return {
      controls_open:this.props.controls_open
    }
  },
  quiet_react: function (){
    // The whole “having to quiet react” doesn’t really sit well with me. Open to other ideas here.
    const quiet = 'react';
  },
  toggle_controls: function(controls_open) {
    this.setState({
      controls_open: controls_open
    });
  },
  render: function () {
    return (
      <input id="modify_list_checkbox" type="checkbox" checked={this.state.controls_open} className="modify_list_checkbox" name="modify_list_checkbox" onChange={this.quiet_react}/>
    );
  }
});

const ModifyListControls = (props) => {
  const can_use_pivot_lists = (e) => {
    if (props.feature_flags.pivotlist_enabled === -1){
      e.stopPropagation();
      var premium_lists = ReactDOM.render(React.createElement(PremiumBaby, {type:'pivot_lists', title:'Adding data columns', edition:'Organized Edition'}), document.getElementById("modal_content"));
      ColumnControlsActions.toggleColumnControls('');
      open_modal_control();
    }
  }

  const available_columns = <AvailableColumns feature_flags={props.feature_flags} columns_available={props.list_object.columns_available} columns_chosen={props.list_object.columns_chosen} display_sf={props.display_sf}/>;
  const chosen_columns = <ChosenColumns feature_flags={props.feature_flags} list_id={props.list_object.list_id} columns_chosen={props.list_object.columns_chosen}/>;
  return (
    <div className="modify_list_container">
      <div className="modify_list_header">
        <div className="modify_columns_label">Modify Columns</div>
        <CancelColumnsButton columns_chosen={props.list_object.columns_chosen}/>
      </div>
      <div onClick={can_use_pivot_lists} className="modify_list_body">
        <div className="modify_list_available">
          {available_columns}
        </div>
        <div className="modify_list_chosen">
          {chosen_columns}
        </div>
      </div>
    </div>
  )
}

const ColumnRow = createReactClass({
  mixins: [Reflux.listenTo(ColumnSettingsStore,"update_settings"),Reflux.listenTo(RowHoverStore,"update_hover_state"), Reflux.listenTo(RowRemovalStore,"update_removal_state")],
  getInitialState: function(){
    return {
      column_settings:[],
      hover_state:false,
      removal_state:false
    }
  },
  update_settings: function(column_settings) {
    this.setState({
      column_settings: column_settings
    });
  },
  update_hover_state: function(hover_id) {
    if(hover_id === this.props.column_data[0].id){
      this.setState({
        hover_state: true
      });
    }
    else{
      this.setState({
        hover_state: false
      });
    }
  },
  update_removal_state: function(removal_ids) {
    if(removal_ids.includes(this.props.column_data[0].id)){
      this.setState({
        removal_state: true
      });
    }
    else{
      this.setState({
        removal_state: false
      });
    }
  },
  mouse_over (e) {
    clearTimeout(hacky_timer);
    RowHoverActions.updateHover(this.props.column_data[0].id)
  },
  mouse_out (e) {
    hacky_timer = setTimeout(function() { RowHoverActions.updateHover(null) }, 71);
  },
  transform_trial_sponsor_hash: function(data_hash, max_name_length) {
    var sponsor_fragment = null;
    if (data_hash.sponsor) {
      // this needs to use the i18n stuff
      sponsor_fragment = '(sponsor)';
    } else {
      sponsor_fragment = '(non-sponsor)';
    }

    const company_name = data_hash.name.substring(0, max_name_length) + "...";
    const company_dashboard_url = '<a href="/company/' + data_hash.id + '">' + company_name + '</a>';
    return '<span>' + company_dashboard_url + ' ' + sponsor_fragment + '</span>';
  },
  return_hash_value: function(column_name, data_hash, column_index, cell_lengths){
    const data_return = [null,null];
    const data_copy = Object.assign({}, data_hash);
    if(column_name === 'added_from'){
      let type_length = 0;
      let type = '';
      if(data_hash.type){
        type_length = data_hash.type.length;
        type = data_hash.type
      }
      if ((data_hash.title.length + type_length) > cell_lengths[column_index]) {
        data_return[1] = data_hash.title;
        data_copy.title = data_copy.title.substr(0,cell_lengths[column_index]-type_length) + "...";
      }
      if(data_hash.url) {
        data_return[0] = type + '<a href="' + data_copy.url + '">' + data_copy.title + '</a>'
      }
      else{
        data_return[0] = type + data_copy.title
      }
    }
    else if (column_name === 'clinical_trial_lead_sponsor') {
      const ceil_length = cell_lengths[column_index];
      data_return[0] = this.transform_trial_sponsor_hash(data_hash, ceil_length);
      data_return[1] = data_hash.name;
    } else if (column_name === 'clinical_trial_collaborators') {
      const ceil_length = cell_lengths[column_index];
      data_return[0] = this.transform_trial_sponsor_hash(data_hash, ceil_length);
      data_return[1] = data_hash.name;
    }
    return data_return
  },
  return_hash_array_value: function(column_name, data_hash_array, column_index, cell_lengths){
    const data_return = [null,null];
    const data_copy = Object.assign({}, data_hash_array);

    var ceil_length = cell_lengths[column_index];

    if (column_name === 'clinical_trial_collaborators') {
      var collaborator_info_array = data_hash_array.map(dh => this.transform_trial_sponsor_hash(dh, ceil_length));
      data_return[0] = collaborator_info_array.join(',');
      data_return[1] = 'Collaborators';
    }
    return data_return
  },
  render: function () {
    var hover_state = this.state.hover_state === true ? ' list_entry_hover' : '';
    var sponsor = this.props.column_data[0].sponsor;
    var company_removed = '';
    /**
     * Truncate the cell value according to the num of columns (0 to 8 columns). More columns, less text:
     *
     * Num of columns | gt
     * 1 column -> 166
     * 2 columns -> 76
     * 3 columns -> 46
     * 4+ columns -> 25
     */
    const cellLengths = [0, 166, 76, 46, 25, 25, 25, 25, 25];
    if(this.state.removal_state === true){
      company_removed = ' company_removed_fade'
    }

    const class_names = ['list_cell', 'list_other_cell', 'sorted_by_null', 'default_sorted_by_null', 'list_column_0'];

    const widthAdjustment = this.props.columnWidths[this.props.columnWidthsIndex] + 'px';
    const widthStyle = {width: widthAdjustment, maxWidth: widthAdjustment, minWidth: widthAdjustment};

    var content = <div className={class_names.join(' ')} style={widthStyle}><span className={"list_cell_value"  + company_removed}>&nbsp;</span></div>;
    var column_name_to_null = {
        studies_twelve_m:'0',
        studies_i:'0',
        studies_ii:'0',
        studies_iii:'0',
        studies_iv:'0',
        studies_active:'0',
        employee_count:'---',
        studies_completed:'0',
        sf_accounts:'0',
        sf_leads:'0',
        sf_user:'no activity in the past year',
        sf_team:'no activity in the past year',
        sf_open:'N',
        sf_closed:'N',
        funding_twelve_m:'N',
        funding_twentyfour_m:'N',
        orphan_drug_twelve_m:'N',
        preclinical_twelve_m:'N',
        registered_phasei_twelve_m:'0',
        registered_phase_ii_twelve_m:'0',
        registered_phase_iii_twelve_m:'0',
        highest_phase_mentioned:'0'
    };
    var null_effect = [null,0,"null","0",""];
    var financial_columns = ['cumulative_funding_current', 'cumulative_funding_prior_year', 'cumulative_funding_two_years_prior', 'r_and_d_expense_current_year', 'r_and_d_expense_prior_year', 'r_and_d_expense_two_years_prior', 'annual_revenue_prior_year', 'annual_revenue_two_years_prior', 'r_and_d_expense_most_recent','annual_revenue_most_recent']
    if(this.state.column_settings.length > 0){
      content =  _.map(this.props.column_data, function(element, index){
        var settings_index = index-1;
        if(index !== 0) {
          var column_value = element;
          var hover_title = '';

          if (this.props.type_ref === "organizations" && !sponsor) {
            column_value = this.props.nonSponsorLabel;
          } else if(financial_columns.includes(this.state.column_settings[settings_index].column_name)){
            column_value = makeMillions(element);
          }
          else if(null_effect.includes(column_value)) {
            if(Object.keys(column_name_to_null).includes(this.state.column_settings[settings_index].column_name)){
              column_value = column_name_to_null[this.state.column_settings[settings_index].column_name];
            }
            else {
              column_value = '---';
            }
          }
          else {
            if(this.state.column_settings[settings_index].data_type === 'boolean') {
              if(element === true) {
                column_value = 'Y';
              }
              else{
                column_value = 'N';
              }
            }
            else if(this.state.column_settings[settings_index].data_type === 'date') {
              if(this.state.column_settings[settings_index].column_name.startsWith('sf_')){
                column_value = moment.parseZone(element).add(12, 'h').format("MMM DD YYYY");
              }
              else{
                column_value = moment.parseZone(element).format("MMM DD YYYY");
              }
            }
            else if(this.state.column_settings[settings_index].data_type === 'int') {
              column_value = commifyNumber(parseInt(element) || 0);
              if(this.state.column_settings[settings_index].column_name === 'employee_count'){
                if(element === 20000){
                  column_value = '20,000+';
                }
                else{
                  column_value = 'approx. ' + column_value;
                }
              }
            }
            else if(this.state.column_settings[settings_index].data_type === 'hash') {
              var hash_return = this.return_hash_value(this.state.column_settings[settings_index].column_name, element, this.state.column_settings[settings_index].column_index, cellLengths);
              column_value = hash_return[0];
              if (hash_return[1]) {
                hover_title = hash_return[1];
              }
            }
            else if(this.state.column_settings[settings_index].data_type === 'hash_array') {
              var hash_array_return = this.return_hash_array_value(this.state.column_settings[settings_index].column_name, element, this.state.column_settings[settings_index].column_index, cellLengths);
              column_value = hash_array_return[0];
              if (hash_array_return[1]) {
                hover_title = hash_array_return[1];
              }
            }
            else if(this.state.column_settings[settings_index].column_name !== "last_activity") {
              if (column_value.length > cellLengths[this.state.column_settings[settings_index].column_index]) {
                hover_title = column_value;
                column_value = column_value.substr(0,cellLengths[this.state.column_settings[settings_index].column_index]) + "...";
              }
            }
          }
          let class_names = (this.state.column_settings[settings_index].css_classes) ? [this.state.column_settings[settings_index].css_classes] : [];
          class_names.push('list_cell', 'list_column_calc',  'rspec_cell_' + index);
          class_names.push('list_other_cell');
          class_names.push('sorted_by_' + this.state.column_settings[settings_index].current_sort);
          class_names.push('default_sorted_by_' + this.state.column_settings[settings_index].default_sort);

          if(this.props.is_editable === false && !_.include(class_names[0], 'fullstory_excluded')){
            class_names.push('fullstory_excluded');
          }
          return (
            <div title={hover_title} key={index} className={class_names.join(' ')} style={widthStyle}><span dangerouslySetInnerHTML={{ __html: column_value }} className={"list_cell_value list_cell_" +this.state.column_settings[settings_index].column_name + company_removed}></span></div>
          );
        }
      }.bind(this));
    }
    return (
      <div onMouseEnter={this.mouse_over} onMouseLeave={this.mouse_out} className={"list_column_row" +
      " list_entry_row" + hover_state}>
        {content}
      </div>
    );
  }
});

const EmptyColumnRow = createReactClass({
  mixins: [Reflux.listenTo(ColumnSettingsStore,"update_settings")],
  getInitialState: function(){
    return {
      column_settings:[]
    }
  },
  update_settings: function(column_settings) {
    this.setState({
      column_settings: column_settings
    });
  },
  render: function () {

    const classNames = ['list_cell', 'list_other_cell', 'list_empty_cell', 'sorted_by_null', 'default_sorted_by_null', 'list_column_0'];

    const widthAdjustment = this.props.columnWidths[this.props.columnWidthsIndex] + 'px';
    const widthStyle = {width: widthAdjustment, maxWidth: widthAdjustment, minWidth: widthAdjustment};

    let content = <div className={classNames.join(' ')} style={widthStyle}>&nbsp;</div>;
    if(this.state.column_settings.length > 0){
      content =  _.map(this.state.column_settings, function(element, index){

        const contentClassNames = ['list_cell', 'list_other_cell', 'list_column_calc', 'list_empty_cell', 'sorted_by_' + this.state.column_settings[index].current_sort, 'default_sorted_by_' + this.state.column_settings[index].default_sort, 'list_column_' + this.state.column_settings[index].column_index];

        return (
          <div key={index} className={contentClassNames.join(' ')} style={widthStyle}>&nbsp;</div>
        );
      }.bind(this));
    }
    return (
      <div className="list_column_row list_empty_row">
        {content}
      </div>
    );
  }
});

const ColumnRows = createReactClass({
  mixins: [Reflux.listenTo(pivotListColumnSettingsStore, 'updateContainerHeightState'), Reflux.listenTo(ColumnControlsStore,"toggleControlsState")],
  getInitialState: function(){
    return {
      controlsOpen: '',
      listTableContainerHeight: pivotListColumnSettingsStore.state.listTableContainerHeight
    }
  },
  updateContainerHeightState: function(newState) {
    this.setState({
      listTableContainerHeight:newState.listTableContainerHeight
    });
  },
  toggleControlsState: function(controlsOpen) {
    this.setState({
      controlsOpen: controlsOpen
    });
  },
  componentDidMount: function() {
    this.scroller.addEventListener("wheel", this.scrollController);
  },
  componentWillUnmount: function() {
    this.scroller.removeEventListener("wheel", this.scrollController);
  },
  scrollController: function(e) {

    if(this.state.controlsOpen === 'checked') {
      return;
    }

    let delta = e.wheelDelta || -e.detail;
    let shiftKey = e.shiftKey;

    ColumnControlsActions.scrollWheelControls(delta, shiftKey, this.scroller);

  },
  render: function () {
    const columnWidthsIndex = this.props.list_object.columns_chosen.length-1;
    const listControls = <ModifyListControls {...this.props}/>;
    const header = <HeaderRow {...this.props} header_type="list_column_header"/>;
    const columns = this.props.list_object.list_entries.map(function(entry, index){
      if(entry[0]){
        return <ColumnRow
          key={index}
          columnWidths={this.props.columnWidths}
          columnWidthsIndex={columnWidthsIndex}
          column_data={entry}
          is_editable={this.props.is_editable}
          nonSponsorLabel={this.props.non_sponsor_label}
          row_index={index}
          type_ref={this.props.list_object.type_ref}
        />
      }
      else {
        return <EmptyColumnRow key={index} columnWidths={this.props.columnWidths} columnWidthsIndex={columnWidthsIndex} />
      }
    }.bind(this));
    const controls_open = this.props.list_view === 'first' ? 'checked' : '';
    const modify_list_checkbox = <ModifyListCheckbox controls_open={controls_open}/>;

    const listTableContainerHeight = this.state.listTableContainerHeight - 69
    const istTableContainerHeightInPx = listTableContainerHeight + 'px';
    const adjustedStyle = {maxHeight: istTableContainerHeightInPx, height: istTableContainerHeightInPx, minHeight: istTableContainerHeightInPx, overflow: 'hidden'};

    return (
      <div className="list_columns_container">
        <div className="list_controls_flipper">
          {modify_list_checkbox}
          <div className="list_columns" id="horizontal_scroll_group">
            {header}
            <div ref={elem => this.scroller = elem} id='list_entries_scroll_data' style={adjustedStyle}>
              {columns}
            </div>
          </div>
          <div className="list_controls">
            {listControls}
          </div>
        </div>
      </div>
    );
  }
});

export default ColumnRows;
