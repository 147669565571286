import _ from "underscore";

import Button from "@javascript/reactComponents/lds/Button";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";

import Heading from "@javascript/reactComponents/lds/typography/Heading";
import Icon from "@javascript/reactComponents/lds/Icon";
import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  ModalBody,
  ModalFooter,
} from "@javascript/reactComponents/lds/modal/Modal";

import {
  SalesforceFormErrorMessages,
  SalesforceModalFormIssue,
  SalesforceModalLoading,
} from "@javascript/reactComponents/salesforce/SalesforceModalMessages";

import SfFormFieldsCore from "@javascript/reactComponents/companyDashboard/salesforce/sfFormFieldsCore";
import SfFormFieldsOptional from "@javascript/reactComponents/companyDashboard/salesforce/sfFormFieldsOptional";

const SfContactForm = createReactClass({
  getInitialState(){
    return {
      contactSFLink: null,
      coreFields: null,
      created: false,
      errorBuildingForm: false,
      errors: false,
      optionalFields: null,
    };
  },
  retrieveSfContactFields(){
    let url = "/company/" + this.props.org_id + "/retrieve_salesforce_contact_fields";
    let data = { zi_contact_id: this.props.zi_contact_id, sf_org_id: this.props.sf_org_id };
    let self = this;

    $.ajax({
      url: url,
      type: "GET",
      data: data,
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        const validFields = crmHelpers.pruneInvalidFields(results.fields);
        const [coreFields, optionalFields] = _.partition(validFields, (field) => field.opt_custom !== true);
        self.setState({
          coreFields: coreFields,
          optionalFields: optionalFields,
        });
      },
      error: function(e) {
        console.error(e);
        self.setState({ errorBuildingForm: true });
      },
      statusCode: {
        401: () => { 
          location.reload()
        }
      }
    });
  },
  createContact(e) {
    e.preventDefault();
    let data = _.object(_.map( $(".sf-account-form").serializeArray(), _.values));

    const accountField = _.find(this.state.coreFields, function(field){
      return field.name === "AccountId";
    });

    data["AccountId"] = accountField.value;
    data["org_id"] = this.props.org_id;

    let url = "/salesforce/contacts/";
    let self = this;

    $.ajax({
      url: url,
      type: "POST",
      data: data,
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        let params = data;

        if (results.response.status === 400) {
          self.setState({
            errors: {
              error_fields: results.error_fields,
              error_messages: results.error_messages,
              error_codes: results.error_codes
            }
          });
        }
        else {
          let contactSFLink = null;
          if (Zymewire.user.feature_tags.includes("rolodex_v3")){
            let contacts = {
              user_zinfo: results.user_zinfo,
              current_contacts: results.current_contacts
            };
            let sfAddedContact = contacts.current_contacts.find(contact =>
              (contact.email === params.Email) || (contact.phone.mobile === params.Phone)
            );
            if (sfAddedContact) {
              contactSFLink = sfAddedContact.sales_force_contacts?.[0]?.url ?? "https://salesforce.com/";
            }
            ContactSearchAction.contactAddedToSalesforce(contacts);
          }
          self.setState({ errors: false, created: true, contactSFLink: contactSFLink });
          SFAccountLoaderActions.refreshAccount(results.account_id);
        }
      },
      error: function(e){
        if(e.status === 401){
          location.reload();
        }
      }
    });
  },

  viewContact (e) {
    e.preventDefault();
    if (this.state.contactSFLink){
      window.open(this.state.contactSFLink, '_blank');
    }
  },

  // Renders the form entities
  // In the initial state it checks if state.coreFields has value:
  // IF IT DOES => Render the form with the fields
  // IF NOT     => Then call retrieve() fields to get the required fields from the Restforce API
  //               and display a message to the user informing them to wait while the form is
  //               being generated
  render() {
     if (this.state.coreFields) {
      if (this.state.created) {
        return (
          <>
            <ModalBody className="flex-col align-center justify-center">
              <Icon
                name={"special-success"}
              />
              <Heading size="h2">Contact added successfully!</Heading>
            </ModalBody>
            <ModalFooter align="flex-end">

              <ButtonGroup size="medium">
                <Button
                  onClick={LegacyModalService.closeModal}
                  size="medium"
                  variant="secondary"
                >
                  Close
                </Button>
                <Button
                  id="view_in_salesforce"
                  onClick={this.viewContact}
                  size="medium"
                  variant="primary"
                >
                  View in Salesforce
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </>
        )
      }
      else {
        return (
          <>
            <form className="sf-account-form">
              <ModalBody className="lds-modal-body-height">
                <SfFormFieldsCore
                  errors={this.state.errors}
                  fields={this.state.coreFields}
                />
                <SfFormFieldsOptional
                  errors={this.state.errors}
                  fields={this.state.optionalFields}
                />
              </ModalBody>
              <ModalFooter align="flex-end">
                <SalesforceFormErrorMessages
                  errors={ this.state.errors }
                />
                <ButtonGroup size="medium">
                  <Button
                    onClick={LegacyModalService.closeModal}
                    size="medium"
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={this.createContact}
                    size="medium"
                    variant="primary"
                  >
                    Add Contact
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          </>
        )
      }
    }
    else {
      if (this.state.errorBuildingForm) {
        return <SalesforceModalFormIssue />
      }
      else {
        this.retrieveSfContactFields();
        return <SalesforceModalLoading />
      }
    }
  }
});

export default SfContactForm;
