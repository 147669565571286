import _ from "underscore";

import Button from "@javascript/reactComponents/lds/Button";
import ButtonGroup from "@javascript/reactComponents/lds/ButtonGroup";

import Heading from "@javascript/reactComponents/lds/typography/Heading";
import Icon from "@javascript/reactComponents/lds/Icon";
import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  ModalBody,
  ModalFooter,
} from "@javascript/reactComponents/lds/modal/Modal";

import {
  SalesforceFormErrorMessages,
  SalesforceModalFormIssue,
  SalesforceModalLoading,
} from "@javascript/reactComponents/salesforce/SalesforceModalMessages";

import SfFormFieldsCore from "@javascript/reactComponents/companyDashboard/salesforce/sfFormFieldsCore";
import SfFormFieldsOptional from "@javascript/reactComponents/companyDashboard/salesforce/sfFormFieldsOptional";

const SfLeadForm = createReactClass({
  getInitialState(){
    return {
      contactSFLink: null,
      coreFields: null,
      created: false,
      errorBuildingForm: false,
      errors: false,
      optionalFields: null,
    };
  },
  retrieveSfLeadFields(){
    let url = "/company/" + this.props.org_id + "/retrieve_salesforce_lead_fields";
    let data = { zi_contact_id: this.props.zi_contact_id };
    let self = this;

    $.ajax({
      url: url,
      type: "GET",
      data: data,
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        const validFields = crmHelpers.pruneInvalidFields(results.fields);
        const [coreFields, optionalFields] = _.partition(validFields, (field) => field.opt_custom !== true);
        self.setState({
          coreFields: coreFields,
          optionalFields: optionalFields,
        });
      },
      error: function(e){
        console.error(e);
        self.setState({ errorBuildingForm: true });
      },
      statusCode: {
        401: () => { 
          location.reload()
        }
      }
    });
  },
  createLead(e) {
    e.preventDefault();
    const data = _.object(_.map( $(".sf-account-form").serializeArray(), _.values));
    data["org_id"] = this.props.org_id;

    const url = "/salesforce/leads/";
    let self = this;

    $.ajax({
      url: url,
      type: "POST",
      data: data,
      headers: {
        // form_authenticity_token TODO: this is ignored, yet somehow the header is still set (deprecate this header and it still works)
        "X-CSRF-Token": "<%= form_authenticity_token.to_s %>"
      },
      success: function(results) {
        let params = data;

        if (results.response.status === 400) {
          self.setState({ errors: {error_fields: results.error_fields, error_codes: results.error_codes, error_messages: results.error_messages} });
        }
        else {

          let contactSFLink = "https://salesforce.com/";

          if (Zymewire.user.feature_tags.includes("rolodex_v3")){
            const contacts = {
              user_zinfo: results.user_zinfo,
              current_contacts: results.current_contacts
            };

            let sfAddedLead = contacts.current_contacts.find(contact =>
              (contact.email === params.Email) || (params.Phone !== "" && contact.phone.mobile === params.Phone)
            );

            if (sfAddedLead) {
              contactSFLink = sfAddedLead.sales_force_leads?.[0]?.sf_url ?? contactSFLink;
            }
            ContactSearchAction.leadAddedToSalesforce(contacts);
          }
          self.setState({ errors: false, created: true, created_data: results, contactSFLink: contactSFLink});
          SFPanelWrapperActions.updateLeads(results.leads);
        }
      }
    });
  },

  viewLead (e) {
    e.preventDefault();
    if (this.state.contactSFLink){
      window.open(this.state.contactSFLink, '_blank');
    }
  },

  // Renders the form entities
  // In the initial state it checks if state.coreFields has value:
  // IF IT DOES => Render the form with the fields
  // IF NOT     => Then call retrieve() fields to get the required fields from the Restforce API
  //               and display a message to the user informing them to wait while the form is
  //               being generated
  render() {
    console.log({state: this.state})
    if (this.state.coreFields) {
      if (this.state.created) {
        return (
          <>
            <ModalBody className="flex-col align-center justify-center">
              <Icon
                name={"special-success"}
              />
              <Heading size="h2">Lead added successfully!</Heading>
            </ModalBody>
            <ModalFooter align="flex-end">
              <ButtonGroup size="medium">
                <Button
                  onClick={LegacyModalService.closeModal}
                  size="medium"
                  variant="secondary"
                >
                  Close
                </Button>
                <Button
                  id="view_in_salesforce"
                  onClick={this.viewLead}
                  size="medium"
                  variant="primary"
                >
                  View in Salesforce
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </>
        )
      }
      else {
        return (
          <>
            <form className="sf-account-form">
              <ModalBody className="lds-modal-body-height">
                <SfFormFieldsCore
                  errors={this.state.errors}
                  fields={this.state.coreFields}
                />
                <SfFormFieldsOptional
                  errors={this.state.errors}
                  fields={this.state.optionalFields}
                />
              </ModalBody>
              <ModalFooter align="flex-end">
                <SalesforceFormErrorMessages
                  errors={ this.state.errors }
                />
                <ButtonGroup size="medium">
                  <Button
                    onClick={LegacyModalService.closeModal}
                    size="medium"
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={this.createLead}
                    size="medium"
                    variant="primary"
                  >
                    Add Lead
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          </>
        )
      }
    } else {
      if (this.state.errorBuildingForm) {
        return <SalesforceModalFormIssue />
      }
      else {
        this.retrieveSfLeadFields();
        return <SalesforceModalLoading />
      }
    }
  }
});

export default SfLeadForm;
