import Button from "@javascript/reactComponents/lds/Button";
import LegacyModalService from "@javascript/globals/LegacyModalService";

import {
  ModalBody,
  ModalFooter,
} from "@javascript/reactComponents/lds/modal/Modal";

export const SalesforceFormErrorMessages = ({
  errors,
}) => {

  const errorText = (errorCode) => {
    const defaultMessage = "Oops. There seems to be an error while creating a record in Salesforce. Please try again later. If the error occurs again please contact support.";
    let message = errors.error_messages.join(" and ") || defaultMessage;

    switch(errorCode) {
      case "REQUIRED_FIELD_MISSING":
        message =  "One or more required fields are missing";
        break;
      case "DUPLICATE_VALUE":
        message = "One or more unique fields has a duplicate value";
        break;
      case "JSON_PARSER_ERROR":
        message = "One or more fields has the wrong data type";
        break;
      case "UNKNOWN_EXCEPTION":
        message = "There was an error. Please check for duplicate records or for custom fields in Salesforce.";
        break;
    }
    return message;
  };

  const errorMessages = () => {
    const errorStyle = {
      color: "#FF0000"
    };

    return errors.error_codes.map((error, i) => (
      <div key={i}>
        <p style={ errorStyle }>{ errorText(error) }</p>
      </div>
    ))
  };

  return (
    <div className={"salesforce-form-error-messages"}>
      {
        errors.error_codes && errors.error_codes.length > 0
          ? errorMessages()
          : null
      }
    </div>
  )
};



const FormatMessage = ({message}) => {
  return (
    <>
      <ModalBody className="lds-modal-body-height">
        <p>{message}</p>
      </ModalBody>
      <ModalFooter align="flex-end">
        <Button
          onClick={LegacyModalService.closeModal}
          size="medium"
          variant="primary"
        >
          Cancel
        </Button>
      </ModalFooter>
    </>
  )
};

export const SalesforceModalFormIssue = () => <FormatMessage message={"There was an issue building the form. Please try again later."} />;

export const SalesforceModalLoading = () => <FormatMessage message={"Please wait while we generate the form..."} />;

