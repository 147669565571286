import React from "react";
import createReactClass from "create-react-class";

import GenericModal from "../../generic/modals/genericModal";
import GenericModalHeader from "../../generic/modals/genericModalHeader";
import GenericModalBody from "../../generic/modals/genericModalBody";
import GenericModalActions from "../../generic/modals/genericModalActions";
import ButtonGroup from "../../lds/ButtonGroup";
import Button from "../../lds/Button";
import UserListCreateModalStore from "../../../stores/lists/UserListCreateModalStore";
import {
  DEFAULT_LIST_TYPE,
  LIST_CREATE_REQUEST_SUCCESS,
  LIST_CREATE_REQUEST_UNSTARTED,
} from "../../../constants/lists/list_constants";
import DetailsSection from "./listCreateModal/DetailsSection";
import ListCreateStatus from "./listCreateModal/ListCreateStatus";

const ListCreateModal = createReactClass({
  mixins: [Reflux.connect(UserListCreateModalStore)],
  getInitialState() {
    return {
      ...UserListCreateModalStore.data,
      selectedCategory: DEFAULT_LIST_TYPE,
    };
  },

  cancelModal() {
    removeUserListCreateModal();
  },

  generateList() {
    if (
      this.state.selectedCategory === DEFAULT_LIST_TYPE ||
      this.state.createListRequestStatus !== LIST_CREATE_REQUEST_UNSTARTED
    ) {
      return;
    }

    const data = {
      base_query: this.state.baseQuery,
      query: this.state.query,
      name: this.state.listName,
      entity_names: _.map(
        this.state.entityCountsDetails[this.state.selectedCategory].entities,
        "name"
      ),
      entity_ids: _.map(
        this.state.entityCountsDetails[this.state.selectedCategory].entities,
        "id"
      ),
      type_ref:
        this.state.entityCountsDetails[this.state.selectedCategory].type_ref,
    };

    UserListCreateModalStore.onCreateList(data);
  },

  categorySelected(value) {
    this.setState({
      selectedCategory: value,
      listName: this.state.categoryListNames[value],
    });
  },

  generateListButtonVariant() {
    if (
      this.state.selectedCategory !== DEFAULT_LIST_TYPE &&
      this.state.createListRequestStatus === LIST_CREATE_REQUEST_UNSTARTED &&
      this.state.listName
    ) {
      return "primary";
    } else {
      return "disable";
    }
  },

  listNameChange(name) {
    UserListCreateModalStore.onListNameChange(name);
  },

  render() {
    let name;

    // This is bit tricky, in order to allow editing the input we need to set the name to null
    // if it is default empty string then you cannot edit the input since it will get the current selectedCategory name
    // The only time we should be using the selectedCategory name is when the user has not entered a name and has just selected a category
    if (
      this.state.listName == null &&
      this.state.selectedCategory !== DEFAULT_LIST_TYPE
    ) {
      name = this.state.categoryListNames[this.state.selectedCategory];
    } else {
      name = this.state.listName;
    }

    return (
      <GenericModal
        open={true}
        style={{ minWidth: "900px", maxWidth: "900px" }}
      >
        <GenericModalHeader
          style={{
            backgroundColor: "#1F2124",
            color: "#fff",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <h2>Create New List From Query</h2>
        </GenericModalHeader>
        <GenericModalBody style={{ padding: "40px 48px 40px 48px" }}>
          {this.state.showDetails && (
            <DetailsSection
              query={this.state.query}
              baseQuery={this.state.baseQuery}
              entityCountsDetails={this.state.entityCountsDetails}
              categorySelected={this.categorySelected}
              selectedCategory={this.state.selectedCategory}
              name={name}
              onListNameChange={this.listNameChange}
            />
          )}

          {!this.state.showDetails && (
            <ListCreateStatus
              status={this.state.createListRequestStatus}
              listName={this.state.listName}
            />
          )}
        </GenericModalBody>
        <GenericModalActions style={{ justifyContent: "flex-end" }}>
          <ButtonGroup size="large">
            <Button
              size="large"
              variant="secondary"
              id="cancel-list-creation"
              onClick={() => {
                this.cancelModal();
              }}
            >
              Cancel
            </Button>
            {this.state.createListRequestStatus !==
            LIST_CREATE_REQUEST_SUCCESS ? (
              <Button
                size="large"
                variant={this.generateListButtonVariant()}
                id="generate-list"
                onClick={() => this.generateList()}
              >
                Generate List
              </Button>
            ) : (
              <Button
                size="large"
                variant="primary"
                id="list-page"
                onClick={() => {
                  window.location.href = `/lists/${this.state.createdListId}`;
                }}
              >
                Go To List
              </Button>
            )}
          </ButtonGroup>
        </GenericModalActions>
      </GenericModal>
    );
  },
});

window.ListCreateModal = ListCreateModal;
export default ListCreateModal;
